export class StaticDataHelper {

    static LocalAssets() {
        return [
            { shortTitle: 'WEND', title: 'WEN DIZEL', image: '/images/WEND_icon.png', address: 'EQBeruz-RXKQFsVHh6busCylN3_VyeZ2KLyC4hiulg-nQVfj' },
            { shortTitle: 'YUME', title: " Vladimir Putin's Dog", image: 'https://assets.dedust.io/images/yume.webp', address: 'EQA0tQ880E68-ZGsugW3kjm5hk_5hUAWTdVggFlJmqqVQgCm' },
            { shortTitle: 'NARUTO', title: 'Naruton', image: 'https://fv5-2.failiem.lv/thumb.php?i=958xu6gzc8&v=1&n=IMG_3134.jpeg', address: 'EQCWsIKwjRilqjt8wQU0Dg7KbgA8kslDZ3zSLhD-QPRwFs9e' },
            { shortTitle: 'SADINDIAN', title: 'Sad Indian ', image: 'https://d121vty759npai.cloudfront.net/images/226e4b0531564252ac7207923e0aad7f.jpeg', address: 'EQC0soznEna-NEZoxeg__S5ppfDNt9m6IajeE2wgxOvjbCNP' },
            { shortTitle: 'PETA', title: 'Petachi', image: 'https://assets.dedust.io/images/peta.webp', address: 'EQAi6vV3FYvPhQnYM-gxlRWXEpKZ8VM9p1v9eopK0Mc-89aS' },
            { shortTitle: 'BULL', title: 'Bull on TON', image: 'https://assets.dedust.io/images/bull.webp', address: 'EQDI_QXYeiwrWN85MrOEucZxsmX8XKNsjehOjzk130E0pn1c' },
            { shortTitle: 'BOLGUR', title: 'Airina Bolgur', image: 'https://cache.tonapi.io/imgproxy/2kTG2QGCIwEIvfoqrLeTXy4kssw28TfErWgrOW4vH4A/rs:fill:200:200:1/g:no/aHR0cHM6Ly9kMTIxdnR5NzU5bnBhaS5jbG91ZGZyb250Lm5ldC9pbWFnZXMvM2JlNjI0MDVhNjBjNGUwMGE2Yjg2OWM0ZjhhYjJlZDguanBlZw.webp', address: 'EQBTjR1nGlxTdRZGSSHeXYvckDkZc3eDwupzBFhz5cDx-eub' }
        ];
    }

    static FindLocalAsset(address) {
        return this.LocalAssets().find(x => x.address == address);
    }

    static StartParamSeparator() {
        return "---";
    }
}